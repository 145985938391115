const AUStateAreaCodes = {
  NSW: '02',
  ACT: '02',
  VIC: '03',
  TAS: '03',
  QLD: '07',
  SA: '08',
  NT: '08',
  WA: '08'
}

const MYStateAreaCodes = {
  KUL: '03',
  SGR: '03',
  PJY: '03',
  KDH: '04',
  PNG: '04',
  PRK: '04',
  PLS: '04',
  MLK: '06',
  NSN: '06',
  JHR: '07',
  LBN: '08',
  SBH: '08',
  SWK: '08',
  KTN: '09',
  TRG: '09',
  PHG: '09'
}

export default { AUStateAreaCodes, MYStateAreaCodes }