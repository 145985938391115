export default class SmsMessageModel {
  constructor(quoteId, customer, rego, jobStart, jobEnd, excess, mobile) {
    this.quoteId = quoteId
    this.customer = customer
    this.rego = rego
    this.jobStart = jobStart
    this.jobEnd = jobEnd
    this.excess = excess
    this.mobile = mobile
    this.message = ''
  }
}
