export default {
  AU: [
    'en-AU'
  ],
  NZ: [
    'mi-NZ',
    'en-NZ'
  ],
  MY: [
    'en-MY',
    'ms-MY',
    'zh-MY',
    'ta-MY'
  ]
}