import _cloneDeep from 'lodash/cloneDeep'

/**
 * Copy toObject property values from fromObject
 * @param {*} toObject Destination object
 * @param {*} fromObject Source object
 */
const shallowCopyValues = function(toObject, fromObject) {
  const keys = Object.keys(toObject)
  keys.forEach(key => {
    if (typeof fromObject[key] !== 'undefined') {
      if (typeof fromObject[key] === 'object') {
        toObject[key] = _cloneDeep(fromObject[key])
      } else {
        toObject[key] = fromObject[key]
      }
    }
  })
}

export { shallowCopyValues }
