<template>
  <div class="columns">
    <div class="column is-narrow">
      <h2 class="subtitle is-6">
        Versions
      </h2>
      <ul>
        <li
          v-for="(v, idx) in versions"
          :key="idx"
          style="margin-bottom: 0.1em;">
          <div class="field has-addons">
            <p class="control">
              <button
                @click="pickVersion(idx)"
                class="button is-small"
                :class="{'is-success': idx === currentVersion}">
                <span class="icon is-small">
                  <i class="mdi mdi-arrow-right" />
                </span>
              </button>
            </p>
            <p class="control">
              <input
                class="input is-small"
                type="text"
                v-model="v.name"
                placeholder="Name"
                @change="versionChanged(idx)">
            </p>
            <p class="control">
              <button
                @click="deleteVersion(idx)"
                class="button is-danger is-small">
                <span class="icon is-small">
                  <i class="mdi mdi-trash-can-outline" />
                </span>
              </button>
            </p>
          </div>
        </li>
        <li
          class="has-text-right"
          style="margin-top:1em;">
          <button
            @click="addVersion()"
            class="button is-primary is-small"
            :disabled="!canAddVersion()">
            <span class="icon is-small">
              <i
                class="mdi mdi-plus mdi-18px"
                aria-hidden="true" />
            </span>
            <span>
              Version
            </span>
          </button>
        </li>
      </ul>
    </div>
    <div
      class="column"
      style="border-left: 1px dotted #c0c0c0">
      <permission-record-childs
        :routes="getSelectedRoutes()"
        @addChild="addRoute"
        @accessChanged="updateRoute"
        @deleteRoute="deleteRoute" />
    </div>
  </div>
</template>

<script>
import PermissionRecordService from './PermissionRecordService'
import PermissionRecordChilds from './PermissionRecordChilds'
import Guid from '@/components/Guid'

export default {
  name: 'PermissionRecordVersions',
  components: { PermissionRecordChilds },
  props: {
    versions: {
      type: Array,
      required: true
    },
    permissionRecordId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentVersion: 0
    }
  },
  computed: {
    routes: function() {
      return this.versions[this.currentVersion]
    }
  },
  methods: {
    permissionRecordVersionChanged: function(v) {
      this.$emit('accessChanged')
    },

    getSelectedRoutes: function() {
      if (this.versions && this.versions[this.currentVersion]) {
        return this.versions[this.currentVersion].routes
      }
      return []
    },

    /* VERSIONING */
    canAddVersion: function() {
      for (var i = 0; i < this.versions.length; i++) {
        var v = this.versions[i]
        if (v.name) {
          continue
        }
        return false
      }

      return true
    },

    addVersion: function() {
      const newVersion = {
        isNew: true,
        name: '',
        routes: []
      }
      this.versions.push(newVersion)
    },

    pickVersion: function(idx) {
      this.currentVersion = idx
      this.$emit('versionSelected', idx)
    },

    deleteVersion: function(idx) {
      if (confirm('Are you sure you want to delete this version?')) {
        this.versions.splice(idx, 1)
        this.$emit('accessChanged')
      }
    },

    versionChanged: function(idx) {
      const version = this.versions[idx]

      version.routes.forEach(d => {
        d.version = version.name
        PermissionRecordService.updateAccessRoute(d)
      })
      this.$notification.success('Route updated')
    },

    /* ROUTES */
    addRoute() {
      this.routes.routes.push({
        id: Guid.newGuid(),
        permissionRecordId: this.permissionRecordId,
        route: '',
        link: ''
      })
      // debugger
    },

    async updateRoute(route) {
      route.version = this.routes.name
      await PermissionRecordService.updateAccessRoute(route)
      this.$notification.success('Route updated')
    },

    async deleteRoute(route) {
      await PermissionRecordService.deleteAccessRoute(route)
      this.$notification.success('Route deleted')
    }
  }
}
</script>
