import { ComponentRoute } from '@/classes'

const CompanySettingRoutes = {
  CompanySettingView: new ComponentRoute('/companysetting', 'CompanySettingView'),
  CompanyDetailSetting: new ComponentRoute('', 'CompanyDetailSetting'),
  CompanyMonthlySetting: new ComponentRoute('shoptargets', 'CompanyShopTargetsSetting'),
  CompanyLicenseSetting: new ComponentRoute('license', 'CompanyLicenseSetting'),
  CompanyModulesSetting: new ComponentRoute('modules', 'CompanyModulesSetting'),
  CompanyInternalSetting: new ComponentRoute('preferences', 'CompanyPreferencesSetting'),
  ItemCategorySetting: new ComponentRoute('itemcategory', 'CompanyItemCategorySetting'),
  CompanyExternalSetting: new ComponentRoute('integration', 'CompanyIntegrationSetting'),
  CompanyEmailSetting: new ComponentRoute('email', 'CompanyEmailSetting')
}

export default CompanySettingRoutes