<template>
  <div class="has-text-white"
    v-if="!!selectedCell"
    style="height: 100%; position: relative;">

    <div class="is-flex is-justify-content-space-between booking-header">
      <span>Bookings</span>
      <span>{{ totalBookings() || 0 }}</span>
      <div style="position:absolute; top: -4px; left: -45px;"
        @click="$emit('handleSidePanelToggle')">
        <div class="side-panel-slide-button">
          <span class="mdi mdi-30px"
            :class="{ 'mdi-chevron-left': !isSidePanelOpen, 'mdi-chevron-right': isSidePanelOpen }" />
        </div>
      </div>
    </div>

    <div class="field search-field-dark">
      <div class="control has-icons-right">
        <input class="input is-capitalized"
          type="text"
          placeholder="Search"
          v-model="searchTextField"
          @keyup.enter="onSearch()">
        <span v-if="!!searchTextField"
          class="icon is-right is-clickable"
          @click="clearSearch">
          <i class="mdi mdi-close has-text-white" />
        </span>
      </div>

    </div>

    <!-- <div class="is-flex is-justify-content-space-between is-align-items-center">
      <div class="is-flex is-align-items-center">
        <div class="field sort-by-field-dark">
          <div class="control is-flex">
            <label class="label">Sort by</label>
            <div class="select">
              <select v-model="selectedSort">
                <option value="0">Due In</option>
                <option value="1">Due Out</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <span class="mdi mdi-sort-descending" />
    </div> -->

    <div style="opacity: 0.5;"
      class="mb-2 is-flex is-justify-content-center is-align-items-center">
      <hr class="is-flex is-flex-grow-1">
      <span class="m-2">{{ selectedCell?.length > 1 ? 'Week Summary' : selectedCell[0].dateString }}</span>
      <hr class="is-flex is-flex-grow-1">
    </div>

    <div class="quote-card-section thin-scrollbar">
      <div v-for="bookings in filteredBookings"
        class="quote-card mb-2"
        @click="handleQuoteRedirect(bookings)"
        :key="bookings.jobNumber">
        <div class="is-flex is-justify-content-space-between quote-card-header">
          <span>
            <div>#{{ bookings.quoteNoRef }}</div>
          </span>
          <span>{{ $filters.formatDateTimeLocale(bookings.jobStart, $userInfo.locale) }}</span>
        </div>
        <div class="quote-body">
          <div class="is-flex is-justify-content-space-between mb-4">
            <span>{{ bookings.owner }}</span>
            <span>{{ bookings.insurer }}</span>
          </div>
          <div class="is-flex is-justify-content-space-between">

            <div class="is-flex is-flex-direction-column">
              <span>{{ bookings.rego }}</span>
              <span>{{ bookings.makeModel }}</span>
            </div>

            <span>{{ $filters.formatCurrency(bookings.totalIncGst, $userInfo.locale, false, 2) }}</span>

          </div>
        </div>
      </div>
    </div>

    <!-- quotes booking by dragging, for later use -->
    <!-- <div v-else-if="!!showQuotes"
      style="height: 95%;">
      <div style="height: 30%;">
        <div style="height: 85%;"
        class="thin-scrollbar quotes-list">
          <div v-for="(i, index) in totalInsurer"
            class="mb-1"
            @click="selectedInsurer = i.str"
            :key="i.str + '-' + index">
            <span>{{ i.str }}</span>

            <div class="progress-bar-progress "
              :class="{'active': selectedInsurer === i.str}">
              <div class="progress-bar-progress-fill animate__animated animate__lightSpeedInLeft"
                :style="{ width: i.percentage + '%' }">
                <span style="font-size: 0.8em;">
                  {{ i.count }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 has-text-black"
        style="height: 69%;">
        <span style="height: 5%;"
          class="has-text-white">
          Quotes to book for {{ selectedInsurer || 'All Insurers' }}
        </span>
        <div class="mt-2 thin-scrollbar quotes-list"
          style=" height:95%;">
          <draggable :list="quoteData.quoteList"
            :sort="false"
            animation="100"
            ghost-class="ghost"
            class="list-group"
            style="cursor: move;"
            draggable=".item">
            <div v-for="(quote, index) in filteredQuoteList"
              class="quote-card mb-1 item animate__animated animate__slideInUp p-2"
              @dragstart="dragStartHandler($event, quote)"
              @dragend="dragEndHandler"
              @drag="dragHandler"
              :key="quote.quoteNo + '-' + index ">

              <div class="is-flex is-justify-content-space-between mr-4">
                <span>
                  Quote. {{ quote.quoteNo }}
                </span>
                <span>
                  {{ quote.insurerName }}
                </span>
              </div>
              <div class="is-flex is-justify-content-space-between">
                <span>{{ quote.quoteStatus }}</span>
                <span>{{ quote.customerName }}</span>
              </div>
            </div>
            <div class="drag-preview"
              :style="dragStyle"
              ref="dragPreview" />
          </draggable>
        </div>
      </div>
    </div>

    <div v-else>
      Select a cell to display data
    </div> -->

  </div>
</template>

<script>
export default {
  name: 'CalendarSidePanel',
  components: {},
  props: {
    quoteData: {
      type: Object,
      required: false
    },
    // when enabled, side panel will function to both show quotes/booking for selected sell
    // and also allow drag and drop of quotes to be booked
    showQuotes: {
      type: Boolean,
      required: false,
      default: false
    },
    isSidePanelOpen: {
      type: Boolean,
      required: true
    },
    selectedCell: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      x: 0,
      y: 0,
      selectedInsurer: null,
      searchTextField: null,
      selectedSort: '0',
      filteredBookings: []
    }
  },
  computed: {
    dragStyle() {
      return {
        top: `${this.y}px`,
        left: `${this.x}px`
      }
    }
    // totalInsurer() {
    //   const listOfInsurers = this.quoteData.quoteList.map((i) => {
    //     return i.insurerName
    //   })

    //   const result = listOfInsurers.reduce((acc, str, index, arr) => {
    //     const count = acc[str] ? acc[str].count + 1 : 1

    //     const percentage = ((count / arr.length) * 100).toFixed(2)
    //     acc[str] = { count, percentage }
    //     return acc
    //   }, {})

    //   return Object.entries(result)
    //     .map(([str, { count, percentage }]) => ({ str, count, percentage }))
    //     .sort((a, b) => b.count - a.count)
    // },
    // filteredQuoteList() {
    //   if (!this.selectedInsurer) return this.quoteData.quoteList
    //   return this.quoteData.quoteList.filter((i) => {
    //     return i.insurerName === this.selectedInsurer
    //   })
    // }
  },
  watch: {
    selectedCell() {
      this.getFilteredBookings()
    },
    searchTextField() {
      this.getFilteredBookings()
    },
    selectedSort() {
      this.getFilteredBookings()
    }
  },
  methods: {
    getFilteredBookings() {
      // do a deep search of all the values in the object

      const allBookings = this.selectedCell
        .map((i) => {
          return i.bookings
        })
        .flat()

      if (!this.searchTextField) {
        this.filteredBookings = allBookings
      } else {
        const text = this.searchTextField.toLowerCase()
        let filteredBookings = allBookings.filter((i) => {
          const regex = new RegExp(text, 'i')
          return Object.values(i).some((value) => regex.test(String(value)))
        })
        this.filteredBookings = filteredBookings
      }
    },

    dragStartHandler(e, quote) {
      this.$emit('handleDragEvent', (e, quote))
      this.$emit('handleQuoteDag', true)
    },
    dragEndHandler() {
      this.$emit('handleQuoteDag', false)
    },
    dragHandler(e) {
      this.x = e.pageX
      this.y = e.pageY
    },
    onSearch() {},
    clearSearch() {
      this.searchTextField = null
    },
    handleQuoteRedirect(quote) {
      this.$emit('handleQuoteRedirect', quote)
    },
    totalBookings() {
      return this.selectedCell.reduce((acc, i) => {
        return acc + i.bookings.length
      }, 0)
    }
  }
}
</script>

<style lang="scss">
.booking-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  font-size: 20px;
  font-weight: 700;
  color: #58a1e8;
  padding-bottom: 1em;
  border-bottom: 2px solid #58a1e8;
  margin-bottom: 1em;
}

.search-field-dark {
  .select select,
  .textarea,
  .input {
    background-color: #495057;
    border: #495057;
    color: #fff;
    &::placeholder {
      color: rgb(255, 255, 255, 0.5);
    }
  }
}

.quote-card-section {
  height: 79%;
  overflow-y: auto;
  padding-right: 0.25em;
}

.quote-card {
  width: 100%;
  background: #495057;
  border-radius: 0.5em;
  color: #fff;
  cursor: pointer;
  opacity: 0.8;

  &:hover {
    opacity: 1;
    transition: all 0.25s ease-in-out;
  }

  .quote-card-header {
    width: 100%;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
    padding: 0.3em;
    background-color: #212529;
  }

  .quote-body {
    padding: 0.3em;
    font-size: 14px;
  }
}
.sortable-ghost {
  border: 1px dashed grey;
  font-size: 0;
  overflow: hidden;
}

.drag-preview {
  position: absolute;
  transform: translateX(-50%) translateY(-50%) rotate(7deg) translateY(55%);
  animation: unset !important;
}

.quotes-list {
  overflow-y: auto;
  padding-right: 0.5em;
  padding-left: 0.5em;
}

.side-panel-slide-button {
  background-color: #6c757d;
  color: #fff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    transition: all 0.25s ease-in-out;
    background-color: #058cba;
  }
}

.sort-by-field-dark {
  .control {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .label {
    color: #fff;
    font-weight: 100;
    margin-bottom: 0px !important;
  }

  .select select,
  .textarea,
  .input {
    background-color: transparent;
    border: none;
    color: #fff;
  }
}
</style>

// .progress-bar-progress {
//   height: 16px;
//   width: 100%;
//   background: #363636;
//   border-radius: 10px;
//   margin-bottom: 0.5em;
//   overflow: hidden;
//   position: relative;
//   &:hover {
//     cursor: pointer;
//     box-shadow: 0 0 10px rgba(5, 140, 186, 0.5);
//     transition: all 0.1s ease-in-out;
//   }

//   &.active {
//     box-shadow: 0 0 10px rgba(5, 140, 186, 0.5);
//     transition: all 0.1s ease-in-out;
//   }

//   .progress-bar-progress-fill {
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background: #058cba;
//     border-radius: 10px;
//   }
// }