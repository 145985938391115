export default {
  // Match SettingName from table CompanyExternalSettings
  SETTING_ORM: 'ORM',
  SETTING_PNET: 'PNET',
  SETTING_EMTA: 'e-MTA',
  SETTING_BODYNET: 'BodyNet',
  SETTING_WEBTRIM: 'WebTrim',
  SETTING_PLANNINGPLUS: 'Planning Plus',
  SETTING_DRIVERCARE: 'DriverCare',
  SETTING_SMS: 'SMS',
  SETTING_PARTSCHECK: 'PartsCheck',
  SETTING_ESTIMAGEFILEPATH: 'EstimageFilePath',
  SETTING_AUDATEX: 'Audatex',
  SETTING_AUTOINTEGRITY: 'AutoIntegrity'
}
