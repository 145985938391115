<template>
  <div class="capacity-page-body">
    <capacity-component @handleQuoteRedirect="handleQuoteRedirect" />
  </div>
</template>

<script>
import CapacityComponent from '../../components/Calendar/CapacityCalendarComponents/CapacityComponent.vue'
import StoreMixin from './storeMixin.js'
export default {
  name: 'CapacityPage',
  components: { CapacityComponent },
  mixins: [StoreMixin],
  methods: {
    handleQuoteRedirect(quote) {
      this.setQuoteReturnRoute(this.$route)
      this.editStoreQuote(quote.quoteId)
      // this.$router.push({ name: this.routeTypes.QuoteBooking.name, params: { quoteId: quote.quoteId }, query: this.$route.query })
    }
  }
}
</script>

<style lang="scss">
.capacity-page-body {
  height: 96vh;
}
</style>