<template>
  <div class="booking-calendar-header"
    :class="[theme]">

    <div class="is-flex is-align-items-center">

      <div class="is-flex is-align-items-center">
        <div class="toggle-buttons ml-2 is-flex is-flex-direction-row">
          <span class="button m-0"
            style="border-radius: 5px 0px 0px 5px;"
            :class="{ 'is-primary is-selected' : selectedDueInOut === 0}"
            @click="changeActive(0)">
            <span>Due In</span>
          </span>
          <span class="button m-0"
            style="border-radius: 0px 5px 5px 0px;"
            :class="{ 'is-primary is-selected' : selectedDueInOut === 1}"
            @click="changeActive(1)">
            <span>Due Out</span>
          </span>
        </div>

        <div class="ml-6 mr-4 is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between"
          style="min-width: 240px;">
          <!-- left arrow icon -->
          <div @click="handleArrowClick('left')">
            <span class="arrow-buttons mdi mdi-chevron-left mdi-30px" />
          </div>
          <div class="is-flex">
            <span class="mdi mdi-calendar-blank-outline" />
            <span class="ml-2 mr-2">{{ dateString }}</span>
          </div>
          <div @click="handleArrowClick('right')">
            <span class="arrow-buttons mdi mdi-chevron-right mdi-30px" />
          </div>
        </div>

        <div class="is-flex is-flex-direction-row">
          <div class="control"
            style="width: 5em;">
            <div class="pretty p-switch p-fill">
              <input type="checkbox"
                @change="handleChangeShowWeekend(!showWeekend)"
                :checked="showWeekend">
              <div class="state p-primary">
                <label>Show Weekends</label>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import integer from 'vuelidate/lib/validators/integer'
export default {
  name: 'BookingCalendarFilterBar',
  props: {
    activeTimePeriod: {
      type: String,
      required: true
    },
    dateString: {
      type: String,
      required: true
    },
    hiddenWeekdays: {
      type: Array,
      required: true
    },
    selectedDueInOut: {
      type: integer,
      required: true
    },
    theme: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      timePeriods: {
        day: {
          key: 'day',
          value: 'Day'
        },
        week: {
          key: 'week',
          value: 'Week'
        },
        month: {
          key: 'month',
          value: 'Month'
        }
      }
    }
  },
  computed: {
    showWeekend() {
      return !this.hiddenWeekdays.includes(7) && !this.hiddenWeekdays.includes(6)
    }
  },
  methods: {
    handleArrowClick(direction) {
      this.$emit('arrowClick', direction)
    },
    handleChangeShowWeekend(bool) {
      this.$emit('changeShowWeekend', bool)
    },
    changeActive(index) {
      this.$emit('changeActive', index)
    }
  }
}
</script>

<style lang="scss" sc>
.booking-calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5%;
  height: 50px;
  z-index: 99999;

  &.dark {
    background-color: #343a40;
    color: #fff;

    .arrow-buttons {
      color: #fff;
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.arrow-buttons {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: #058cba;
    scale: 1.2;
  }
}

.timeperiod-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: unset;
  border: unset;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}
</style>