export default {
  AU: [
    '(UTC+10:30) Lord Howe Island',
    '(UTC+10:00) Hobart',
    '(UTC+10:00) Canberra, Melbourne, Sydney',
    '(UTC+09:30) Adelaide',
    '(UTC+10:00) Brisbane',
    '(UTC+09:30) Darwin',
    '(UTC+08:00) Perth',
    '(UTC+08:45) Eucla'
  ],
  NZ: [
    '(UTC+12:00) Auckland, Wellington',
    '(UTC+12:45) Chatham Islands'
  ],
  MY: [
    '(UTC+08:00) Kuala Lumpur, Singapore'
  ]
}