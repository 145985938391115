<template>
  <div class="tile is-child box has-sticky-tile"
    style="margin-bottom: 1rem !important">
    <div class="is-flex is-align-items-center"
      style="height: 60px">
      <div class="toggle-buttons-vertical ml-2 is-flex is-flex-direction-column mt-2 mb-1">
        <span class="button m-0"
          style="border-radius: 5px 5px 0px 0px;"
          :class="{ 'is-primary is-selected' : innerValue.jobInOut === 0}"
          @click="changeInOut(0)">
          <span>Due In Report</span>
        </span>
        <span class="button m-0"
          style="border-radius: 0px 0px 5px 5px;"
          :class="{ 'is-primary is-selected' : innerValue.jobInOut === 1}"
          @click="changeInOut(1)">
          <span>Due Out Report</span>
        </span>
      </div>
      <div class="ml-2 is-flex is-flex-direction-column mb-1"
        style="height: 100%">
        <div class="field m-0 is-flex is-align-items-end mb-4"
          style="height: 50%">
          <div class="control">
            <div class="select is-small">
              <select v-model="innerValue.selectDateType"
                class="has-text-weight-bold">
                <option value="today">Today</option>
                <option value="tomorrow">Tomorrow</option>
                <option value="next7days">Next 7 days</option>
                <option value="lastweek">Last Week</option>
                <option value="thismonth">This Month</option>
                <option value="custom">Custom</option>
              </select>
            </div>
          </div>
        </div>
        <div class="field is-flex is-flex-direction-row is-align-items-center"
          style="height: 50%">
          <div class="control">
            <v-date-picker
              v-model="selectedFromDate"
              ref="datepicker"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              @input="getData"
              :model-config="selectedFromDate ? {} : modelConfig"
              :max-date="selectedToDate"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input :class="{ 'is-danger' : $v.filter.dateFrom.$error }"
                      type="text"
                      class="input is-small"
                      placeholder="Date From..."
                      :value="inputValue"
                      v-on="inputEvents"
                      v-tippy="{ theme: 'danger', enabled: $v.filter.dateFrom.$error }"
                      content="Date is required">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
          <div class="field mr-2 ml-2">
            <span class="icon">
              <i class="mdi mdi-arrow-right" />
            </span>
          </div>
          <div>
            <v-date-picker v-model="selectedToDate"
              ref="datepicker"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              class="is-fullwidth"
              @input="getData"
              :model-config="selectedToDate ? {} : modelConfig"
              :min-date="selectedFromDate"
              :timezone="$filters.getIanaTimezone()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type="text"
                      class="input is-small"
                      :class="{ 'is-danger' : $v.filter.dateTo.$error }"
                      placeholder="Date To..."
                      :value="inputValue"
                      v-on="inputEvents"
                      v-tippy="{ theme: 'danger', enabled: $v.filter.dateTo.$error }"
                      content="Date is required">
                    <span class="icon is-small is-left">
                      <i class="mdi mdi-calendar mdi-18px" />
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>
      </div>

      <div class="is-flex is-flex-direction-row is-justify-content-space-evenly"
        style="height: 60px; width: 100%;">
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <span class="mt-1 header-text-divider">Total Jobs</span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ totalJobs }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div>

        <div class="is-flex is-flex-direction-column is-align-items-center">
          <span class="mt-1 header-text-divider">{{ innerValue.jobInOut === 0 ? 'Amount Booked' : 'Total Jobs Amount' }}</span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ $filters.formatCurrency(amountBooked, this.$userInfo.locale) }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div>

        <div v-if="innerValue.jobInOut === 1"
          class="is-flex is-flex-direction-column is-align-items-center">
          <span class="mt-1 header-text-divider">Avg Daily Amount</span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ avgDailyAmount }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div>

        <div v-if="innerValue.jobInOut === 1"
          class="is-flex is-flex-direction-column is-align-items-center">
          <span class="mt-1 header-text-divider">Completed Amount</span>
          <span v-if="!isLoading"
            class=" is-size-4 has-text-weight-bold">{{ amountCompleted }}</span>
          <div v-else
            class="spins">
            <span class="mdi mdi-loading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import integer from 'vuelidate/lib/validators/integer'

export default {
  name: 'ProductionInOutMenuBar',
  inject: ['$vv'],
  props: {
    value: null,
    data: null,
    days: {
      type: integer,
      value: 0
    },
    totalJobs: {
      type: integer,
      value: 0
    },
    isLoading: {
      type: Boolean,
      value: false
    },
    includeGst: {
      type: Boolean,
      value: false
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      selectedFromDate: null,
      selectedToDate: null,
      jobInOut: 0,
      jobStageCompleted: [8, 10, 19],
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    amountBooked() {
      const total = this.data.reduce((acc, job) => {
        return acc + job.totalIncGst
      }, 0)
      return total
    },
    avgDailyAmount() {
      let total = 0
      if (this.amountBooked !== 0) {
        total = this.amountBooked / this.days
      }
      return this.$filters.formatCurrency(total, this.$userInfo.locale)
    },
    amountCompleted() {
      const completedJobs = this.data.filter(job => this.jobStageCompleted.includes(job.jobStage))
      const sumTotalIncGst = completedJobs.reduce((sum, job) => sum + job.totalIncGst, 0)
      return this.$filters.formatCurrency(sumTotalIncGst, this.$userInfo.locale)
    }
  },
  watch: {
    selectedFromDate: function (newVal, oldVal) {
      if (newVal !== null) {
        this.innerValue.dateFrom = this.selectedFromDate.toISOString()
      } else {
        this.innerValue.dateFrom = null
      }
    },
    selectedToDate: function (newVal, oldVal) {
      if (newVal !== null) {
        this.innerValue.dateTo = this.selectedToDate.toISOString()
      } else {
        this.innerValue.dateTo = null
      }
    },
    'innerValue.selectDateType': function (newVal, oldVal) {
      if (newVal === 'today') {
        this.getTodayDate()
      } else if (newVal === 'tomorrow') {
        this.getTomorrowDate()
      } else if (newVal === 'next7days') {
        this.getNext7DaysDates()
      } else if (newVal === 'thisweek') {
        this.getThisWeekDates()
      } else if (newVal === 'lastweek') {
        this.getPreviousWeekDates()
      } else if (newVal === 'thismonth') {
        this.getThisMonthDates()
      }
    },
    'innerValue.jobInOut': function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.jobInOut = parseInt(newVal)
        this.getData()
      }
    },
    innerValue: {
      handler: function (newVal, oldVal) {
        // alert('emit input ' + JSON.stringify(newVal))
        this.$emit('input', newVal)
        if (this.innerValue.dateFrom !== null && this.innerValue.dateTo !== null) {
          this.$emit('filter-list')
        }
      },
      deep: true
    }
    // value: {
    //   handler: function (newVal, oldVal) {
    //     this.innerValue = this.value
    //   },
    //   deep: true
    // }
  },
  created() {
    if (this.innerValue.dateFrom) {
      this.selectedFromDate = new Date(this.innerValue.dateFrom)
    }
    if (this.innerValue.dateTo) {
      this.selectedToDate = new Date(this.innerValue.dateTo)
    }
  },
  mounted() {
    this.$v.filter.$touch()
  },
  beforeDestroy() {
    const elements = document.getElementsByClassName('datepicker')
    Array.from(elements).forEach((element, index, array) => {
      const parent = element.parentNode
      if (parent.nodeName === 'BODY') {
        parent.removeChild(element)
      }
    })
  },
  methods: {
    changeInOut(type) {
      if (this.selectedFromDate === null || this.selectedToDate === null) {
        return false
      } else {
        this.innerValue.jobInOut = type
        this.getData()
      }
    },
    getTooltipContent(index) {
      return this.$refs[`inout${index}`].innerHTML
    },
    getData() {
      if (this.selectedFromDate === null || this.selectedToDate === null)
        return false

      if (this.innerValue.dateFrom  !== this.selectedFromDate.toISOString() || this.innerValue.dateTo  !== this.selectedToDate.toISOString())
        this.innerValue.selectDateType = 'custom'

      this.innerValue.dateFrom = this.selectedFromDate !== null ? this.selectedFromDate.toISOString() : ''
      this.innerValue.dateTo = this.selectedToDate !== null ? this.selectedToDate.toISOString() : ''
    },
    getTodayDate() {
      let today = new Date()
      today = new Date(today.setHours(0, 0, 0, 0))
      this.selectedFromDate = today
      this.selectedToDate = today
    },
    getTomorrowDate() {
      let tomorrow = new Date(new Date().setDate(new Date().getDate() + 1))
      tomorrow = new Date(tomorrow.setHours(0, 0, 0, 0))
      this.selectedFromDate = tomorrow
      this.selectedToDate = tomorrow
    },
    getNext7DaysDates() {
      let fromDate = new Date(new Date().setDate(new Date().getDate() + 1))
      fromDate = new Date(fromDate.setHours(0, 0, 0, 0))

      let toDate = new Date(new Date().setDate(new Date().getDate() + 7))
      toDate = new Date(toDate.setHours(0, 0, 0, 0))

      this.selectedFromDate = fromDate
      this.selectedToDate = toDate
    },
    getThisWeekDates() {
      let today = new Date()
      let currentDay = today.getDay() // 0 is Sunday, 1 is Monday, ..., 6 is Saturday

      // Calculate the difference between the current day and Monday (start of the week)
      let diff = today.getDate() - currentDay + (currentDay === 0 ? -6 : 1)
      let startOfWeek = new Date(today.setDate(diff))
      let endOfWeek = new Date(startOfWeek)
      endOfWeek = new Date(endOfWeek.setDate(startOfWeek.getDate() + 6))

      startOfWeek = new Date(startOfWeek.setHours(0, 0, 0, 0))
      endOfWeek = new Date(endOfWeek.setHours(0, 0, 0, 0))

      this.selectedFromDate = startOfWeek
      this.selectedToDate = endOfWeek
    },
    getPreviousWeekDates() {
      let today = new Date()
      let currentDay = today.getDay() // 0 is Sunday, 1 is Monday, ..., 6 is Saturday

      // Calculate the difference between the current day and Monday (start of the week)
      let diff = today.getDate() - currentDay - 6

      let startOfPreviousWeek = new Date(today.setDate(diff))

      // End of the previous week is one day before the start (Monday to Sunday)
      let endOfPreviousWeek = new Date(startOfPreviousWeek)
      endOfPreviousWeek = new Date(endOfPreviousWeek.setDate(startOfPreviousWeek.getDate() + 6))

      startOfPreviousWeek = new Date(startOfPreviousWeek.setHours(0, 0, 0, 0))
      endOfPreviousWeek = new Date(endOfPreviousWeek.setHours(0, 0, 0, 0))

      this.selectedFromDate = startOfPreviousWeek
      this.selectedToDate = endOfPreviousWeek
    },
    getThisMonthDates() {
      const today = new Date()
      let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

      firstDayOfMonth = new Date(firstDayOfMonth.setHours(0, 0, 0, 0))
      lastDayOfMonth = new Date(lastDayOfMonth.setHours(0, 0, 0, 0))

      this.selectedFromDate = firstDayOfMonth
      this.selectedToDate = lastDayOfMonth
    }
  }
}
</script>

<style lang="scss" scoped>
.spins {
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.tile.is-child.has-sticky-tile {
  position: sticky;
  top: 3.25rem;
  z-index: 31;
}
.header-text-divider {
  border-bottom: 1px solid lightgray;
}
</style>