export default class AddressModel {
  constructor(assetID) {
    this.id = null
    this.assetID = assetID
    this.type = '1'
    this.address1 = null
    this.address2 = null
    this.address3 = null
    this.suburbText = null
    this.suburbPostcode = null
    this.stateCode = null
    this.stateText = null
    this.countryCode = null
    this.countryText = null
    this.displayAddress = null
    this.isDefault = false
    this.isNew = true
    this.isDeleted = false
    this.isDirty = false
    this.overwriteOnConflict = false
    this.mergeOnConflict = false
    this.stateAreaCode = null
    this.countryCallingCode = null
  }
}
